var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"the__button",class:{
  round__corners: _vm.isRounded,
  long: _vm.isLong,
  big: _vm.isBig,
  small: !_vm.isBig,
  smallText: _vm.isMobile,
  biggerBtn: _vm.isReg,
  bold: _vm.isBold,
  notBold: !_vm.isBold
},style:({ 'background-color': _vm.bgColor, 'color': _vm.color }),on:{"click":_vm.event}},[_vm._v(" "+_vm._s(_vm.text)+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }